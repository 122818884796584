import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe4.css'

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import StopMotion from "../images/StopMotion_PapelotedeBacalhau.mp4"
import cardfoto1 from "../images/BacalhauaBraz_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/BacalhauLegumes_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/BacalhaucomGrao_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto4 from "../images/PapelotedeBacalhau.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image


const ReceitaD = () => (
  <Layout>
    <SEO title="Bacalhau Mil e Uma Maneiras - Receita 2" />

    <div id="rec4-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo playfair-black">
            Papelote de Bacalhau e Espargos
        </div>
        <div className="desc sneak-regular">
        Trazemos o fiel amigo à sua mesa, num prato colorido e saudável, que promete não desapontar.
        </div>
        </div>
    </div>
    <div id="rec4-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de filetes de Bacalhau Ponto de Sal
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Cebola roxa
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                        Cenoura
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 
                        </div>
                        <div className="text sneak-regular">  
                         Espargos pré-cozidos
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Pimento amarelo
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1/2
                        </div>
                        <div className="text sneak-regular">  
                            Alho francês
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4
                        </div>
                        <div className="text sneak-regular">  
                         Tomates cherry
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            10cl
                        </div>
                        <div className="text sneak-regular">  
                        Azeite
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Salsa
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Sal e pimenta
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 folhas
                        </div>
                        <div className="text sneak-regular">  
                        Papel vegetal 
                        </div>
                        
                    </div>
                  
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                        Numa folha de papel vegetal adicione, a cebola às rodelas, o pimento, a cenoura e alho francês laminado, os espargos e os tomates cortados em metades. Regue com um pouco de azeite.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 -
                        </div>
                        <div className="text sneak-regular">  
                    Adicione o bacalhau no topo dos vegetais.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Cuidadosamente feche com o papel vegetal como um envelope dobrando bem de forma a que o calor não escape.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Leve ao forno previamente aquecido a 180º graus durante 20 minutos.                   
                          </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5 - 
                        </div>
                        <div className="text sneak-regular">  
                        Retire do forno e abra o envelope. Sirva salpicado com salsa picada.                          </div>
                        
                    </div>
       
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="rec4-section-3">
        <div className="wrapper">
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={StopMotion} type="video/mp4" />
         </video>

            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                        Este vídeo exemplificativo contém todos os passos necessários para elaborar a receita utilizando as melhores técnicas.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            293
                        </div>
                        <div className="titulo sneak-regular">
                            7
                        </div>
                        <div className="titulo sneak-regular">
                           1
                        </div>
                        <div className="titulo sneak-regular">
                          15
                        </div>
                        <div className="titulo sneak-regular">
                         13
                        </div>
                        <div className="titulo sneak-regular">
                          43
                        </div>
                        <div className="titulo sneak-regular">
                         9
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>


  </Layout>
)


export default ReceitaD
